import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>404: No Encontrado</h1>
    <p>Página No Encontrada, verifique por algun error de escritura</p>
  </Layout>
)

export default NotFoundPage